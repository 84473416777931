
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import {Routes} from "@/helpers/Routes";
	import SkinAmountChip from "@/components/skin/SkinAmountChip.vue";
	import SkinHelper from "@/helpers/SkinHelper";
	import SkinPriceChip from "@/components/skin/SkinPriceChip.vue";
	import SkinChipLinkRustlabs from "@/components/skin/SkinChipLinkRustlabs.vue";
	import SkinChipLinkMarket from "@/components/skin/SkinChipLinkMarket.vue";
	import EntityCard from "@/components/common/EntityCard.vue";
	import SkinChipLinkWorkshop from "@/components/skin/SkinChipLinkWorkshop.vue";
	import SkinChipLinkAuthorWorkshop from "@/components/skin/SkinChipLinkAuthorWorkshop.vue";
	import SkinChipLinkScmm from "@/components/skin/SkinChipLinkScmm.vue";

	@Component({
		components: {
			SkinChipLinkScmm,
			SkinChipLinkAuthorWorkshop,
			SkinChipLinkWorkshop,
			EntityCard, SkinChipLinkMarket, SkinChipLinkRustlabs, SkinPriceChip, SkinAmountChip
		}
	})
	export default class SkinCard extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public showFavourite!: boolean;

		private hover: boolean = false;
		private cardWidth: number = 0;

		public mounted(): void {
			this.updateCardWidth();
			window.addEventListener('resize', this.updateCardWidth);
		}

		public beforeDestroy(): void {
			window.removeEventListener('resize', this.updateCardWidth);
		}

		private get vertical(): boolean {
			return this.cardWidth < 165;
		}

		private get iconBgColor(): string | null {
			if (this.amount === 0) return null;
			if (this.amount > 1) return '#e1e9e5';
			return '#e1e5e9';
		}

		private get progressColor(): string | null {
			if (this.amount === 0) return 'grey';
			if (this.amount > 1) return 'success';
			return 'info';
		}

		private get amount(): number {
			return this.$store.getters['inventory/amountBySkinId'](this.skin.id);
		}

		private get iconUrl(): string | null {
			return this.$store.state.ui.showAvatars
				? SkinHelper.iconUrl(this.skin)
				: null;
		}

		private get isFavourite(): boolean {
			return this.$store.getters['skin/isFavourite'](this.skin.id);
		}

		private get card(): Vue | null {
			return this.$refs.card as Vue | null;
		}

		public updateCardWidth(): void {
			this.$nextTick(() => {
				this.cardWidth = this.card?.$el.clientWidth ?? 0;
			});
		}

		private showSkin(): void {
			this.$router.push({name: Routes.skin, params: {id: this.skin.id.toString()}});
		}

	}
