
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import {Routes} from "@/helpers/Routes";
	import {ISkin} from "@/models/ISkin";
	import ItemChip from "@/components/item/ItemChip.vue";
	import SkinCard from "@/components/skin/SkinCard.vue";
	import SkinAmountChip from "@/components/skin/SkinAmountChip.vue";
	import KitChip from "@/components/kit/KitChip.vue";
	import {IKit} from "@/models/IKit";
	import ArrayHelper, {MultiSortHandler} from "@/helpers/ArrayHelper";
	import ReloadButton from "@/components/common/ReloadButton.vue";
	import SkinPriceChip from "@/components/skin/SkinPriceChip.vue";
	import Timestamp from "@/components/common/Timestamp.vue";
	import SkinFavouriteButton from "@/components/skin/SkinFavouriteButton.vue";
	import SkinChipLinkMarket from "@/components/skin/SkinChipLinkMarket.vue";
	import SkinChip from "@/components/skin/SkinChip.vue";
	import SkinChipLinkRustlabs from "@/components/skin/SkinChipLinkRustlabs.vue";
	import SkinListFilter from "@/models/filter/SkinListFilter";
	import SkinListPreference from "@/models/list-preference/SkinListPreference";
	import ListHelper from "@/helpers/ListHelper";
	import SkinTradeableIcon from "@/components/skin/SkinTradeableIcon.vue";
	import SortControl, {SortTemplateOption} from "@/components/common/SortControl.vue";
	import FilterControl from "@/components/common/FilterControl.vue";
	import ItemGroupAutocomplete from "@/components/item-group/ItemGroupAutocomplete.vue";
	import ItemAutocomplete from "@/components/item/ItemAutocomplete.vue";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import IProgress from "@/models/IProgress";
	import IOption from "@/models/IOption";
	import SkinChipLinkWorkshop from "@/components/skin/SkinChipLinkWorkshop.vue";
	import IFilterControlTemplate from "@/models/IFilterControlTemplate";
	import ISkinWorkshopData from "@/models/ISkinWorkshopData";
	import SkinChipLinkAuthorWorkshop from "@/components/skin/SkinChipLinkAuthorWorkshop.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import SkinPricingEntriesMultiFetchDialog from "@/components/skin/SkinPricingEntriesMultiFetchDialog.vue";
	import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
	import SkinKitsDialog from "@/components/skin/SkinKitsDialog.vue";
	import SkinSetItemDialog from "@/components/skin/SkinSetItemDialog.vue";
	import SkinChipLinkScmm from "@/components/skin/SkinChipLinkScmm.vue";
	import SkinSetClassIdDialog from "@/components/skin/SkinSetClassIdDialog.vue";
	import SkinSetWorkshopIdDialog from "@/components/skin/SkinSetWorkshopIdDialog.vue";

	@Component({
		components: {
			SkinSetWorkshopIdDialog,
			SkinSetClassIdDialog,
			SkinChipLinkScmm,
			SkinSetItemDialog, SkinKitsDialog,
			ConfirmDialog,
			SkinPricingEntriesMultiFetchDialog,
			SkinChipLinkAuthorWorkshop,
			SkinChipLinkWorkshop,
			SkinAvatar,
			ItemAutocomplete,
			ItemGroupAutocomplete,
			FilterControl,
			SortControl,
			SkinTradeableIcon,
			SkinChipLinkRustlabs,
			SkinChip,
			SkinChipLinkMarket,
			SkinFavouriteButton,
			Timestamp, SkinPriceChip, ReloadButton, KitChip, SkinAmountChip, SkinCard, ItemChip
		}
	})
	export default class SkinList extends Vue {

		@Prop({type: Array, default: () => []})
		public value!: number[];

		@Prop({type: Number, default: null})
		public itemId!: number | null;

		@Prop({type: Number, default: null})
		public kitId!: number | null;

		@Prop({type: Boolean, default: true})
		public showValue!: boolean;

		@Prop({type: Boolean, default: false})
		public hideColumnsTrading!: boolean;

		@Prop({type: Boolean, default: false})
		public hideColumnKit!: boolean;

		@Prop({type: Boolean, default: false})
		public hideColumnItem!: boolean;

		@Prop({type: Boolean, default: false})
		public hideItemFilter!: boolean;

		@Prop({type: Boolean, default: false})
		public hideItemGroupFilter!: boolean;

		@Prop({type: Boolean, default: false})
		public showSelect!: boolean;

		@Prop({type: String, default: null})
		public memoryKey!: string | null;

		public readonly defaultSortBy: string[] = ['price'];
		public readonly defaultSortDesc: boolean[] = [true];

		public options: AsiListTableOptions = new AsiListTableOptions();
		public filter: SkinListFilter = new SkinListFilter();
		public preference: SkinListPreference = new SkinListPreference();
		public selectedSkins: ISkin[] = [];
		public pricingEntriesMultiFetchDialog: DialogHandler = new DialogHandler();
		private loadingRemoveFromKit: boolean = false;
		public setKitsDialog: DialogHandler = new DialogHandler();
		public setKitsDialogSkin: ISkin | null = null;
		public filterText: string | null = null;
		private filterTextTimeout: any = null;
		private setClassIdSkin: ISkin | null = null;
		private setClassIdDialog: DialogHandler = new DialogHandler(() => this.setClassIdSkin = null);
		private setWorkshopIdSkin: ISkin | null = null;
		private setWorkshopIdDialog: DialogHandler = new DialogHandler(() => this.setWorkshopIdSkin = null);

		public created(): void {
			this.options.sortBy = this.defaultSortBy;
			this.options.sortDesc = this.defaultSortDesc;

			if (this.memoryKey !== null) {
				const options = this.$store.getters['ui/getListOptions'](this.memoryKey);
				if (options !== null) this.options = options;

				const filter = this.$store.getters['ui/getListFilter'](this.memoryKey);
				if (filter !== null) {
					Object.setPrototypeOf(filter, new SkinListFilter());
					this.filter = filter;
					this.filterText = filter.text;
				}

				const preference = this.$store.getters['ui/getListPreference'](this.memoryKey);
				if (preference !== null) this.preference = preference;
			}

			this.loadSkins();
		}

		public get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

		public get isMobile(): boolean {
			return this.$vuetify.breakpoint.xs;
		}

		public get itemsPerPageOptions(): number[] {
			return ListHelper.itemsPerPageOptions;
		}

		public get nullableBooleanOptions(): IOption<boolean | null>[] {
			return ListHelper.nullableBooleanOptions;
		}

		public get skins(): ISkin[] {
			let relevantSkins: ISkin[] = this.$store.state.skin.skins;
			if (this.itemId !== null || this.kitId !== null) {
				relevantSkins = relevantSkins.filter((skin: ISkin) => {
					if (this.itemId !== null && skin.item_id !== this.itemId) return false;
					if (this.kitId !== null && !(this.$store.state.kit.kits.find((k: IKit) => k.id === this.kitId)?.skin_ids.includes(skin.id) ?? false)) return false;
					return true;
				});
			}

			return this.filter.applyFilter(relevantSkins);
		}

		private get skinIds(): number[] {
			return this.skins.map(s => s.id);
		}

		public get skinIdsSorted(): number[] {
			return this.sortEntries(this.skins, this.options.sortBy, this.options.sortDesc).map(s => s.id);
		}

		public get headers(): AsiListTableHeader[] {
			const ret = [
				new AsiListTableHeader('', 'favourite', true, true, 'center', '5rem'),
				new AsiListTableHeader('', 'avatar', false, false, 'center', '5rem'),
				new AsiListTableHeader('Label', 'label', true, true, 'start'),
			];

			if (this.isAdmin && this.preference.adminDataMode) {
				ret.push(
					new AsiListTableHeader('Item', 'item_id', false, false, 'center', '5rem'),
					new AsiListTableHeader('Class', 'class_id', false, false, 'center', '5rem'),
					new AsiListTableHeader('Steam', 'steam_id', false, false, 'center', '5rem'),
					new AsiListTableHeader('Workshop', 'workshop_id', false, false, 'center', '5rem'),
					new AsiListTableHeader('Icon', 'has_icon', false, false, 'center', '5rem'),
					new AsiListTableHeader('Icon HD', 'has_icon_hd', false, false, 'center', '5rem'),
					new AsiListTableHeader('SCMM URL', 'scmm_url', false, false, 'center', '7rem'),
					new AsiListTableHeader('RL URL', 'rustlabs_url', false, false, 'center', '7rem'),
					new AsiListTableHeader('Price Store', 'price_item_store', true, false, 'center', '7rem'),
					new AsiListTableHeader('Author', 'author', true, true, 'center', '10rem'),
					new AsiListTableHeader('Author URL', 'author_workshop_url', false, false, 'center', '10rem'),
					new AsiListTableHeader('Created', 'created_at', true, false, 'center', '8rem'),
					new AsiListTableHeader('Updated', 'updated_at', true, false, 'center', '8rem'),
				);
			} else {
				ret.push(
					new AsiListTableHeader('Author', 'author', true, true, 'end', '10rem'),
					new AsiListTableHeader('Owned', 'amount', true, true, 'center', '7rem'),
				);
			}

			if (!this.hideColumnsTrading) {
				ret.push(
					new AsiListTableHeader('Price', 'price', true, true, 'end', '8rem'),
					new AsiListTableHeader('Tradeable', 'tradeable', true, true, 'center', '7rem'),
				);
			}

			if (!this.hideColumnItem && this.itemId === null) {
				ret.push(new AsiListTableHeader('Item', 'item', true, true, 'start', '15rem'));
			}
			if (!this.hideColumnKit && this.kitId === null) {
				ret.push(new AsiListTableHeader('Kits', 'kits', true, true, 'start', '10rem'));
			}
			if (this.hasActionsSlotContent) {
				ret.push(new AsiListTableHeader('Actions', 'actions', false, false, 'end', '10rem'));
			}

			return ret;
		}

		public get progress(): IProgress {
			return this.$store.getters['inventory/skinProgress'](this.skinIds);
		}

		public get currentTotalOriginalValue(): number {
			return this.$store.getters['skin/originalValueOfSkins'](this.skinIds);
		}

		public get currentTotalValue(): number {
			return this.$store.getters['skin/valueOfSkins'](this.skinIds);
		}

		public get currentInventoryValue(): number {
			return this.$store.getters['inventory/ownedValueOfSkins'](this.skinIds);
		}

		public get currentSpareValue(): number {
			return this.$store.getters['inventory/spareValueOfSkins'](this.skinIds);
		}

		public get currentOpenValue(): number {
			return this.$store.getters['inventory/openValueOfSkins'](this.skinIds);
		}

		public get sortTemplateOptions(): SortTemplateOption[] {
			return [
				{
					label: 'Favourites first',
					icon: 'mdi-star',
					sortBy: ['favourite', 'label'],
					sortDesc: [false, false],
				},
			];
		}

		public get filterControlTemplates(): IFilterControlTemplate<SkinListFilter>[] {
			return [
				{
					icon: 'mdi-account-badge',
					label: 'My Inventory',
					apply: (filter: SkinListFilter) => {
						filter.minOwnedAmount = 1;
					},
				},
			];
		}

		private get hasActionsSlotContent(): boolean {
			return !!this.$slots.actions || !!this.$scopedSlots['actions'];
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: number[]): void {
			if (ArrayHelper.identical<number>(value, this.selectedSkins.map(s => s.id))) return;
			this.selectedSkins = this.$store.state.skin.skins.filter((s: ISkin) => value.includes(s.id));
		}

		@Watch('selectedSkins')
		private onSelectedSkinsChanged(value: ISkin[]): void {
			if (ArrayHelper.identical<number>(this.value, value.map(s => s.id))) return;
			this.$emit('input', value.map(s => s.id));
		}

		@Watch('options', {deep: true})
		private onOptionsChanged(value: AsiListTableOptions): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListOptions', {key: this.memoryKey, options: value});
			}
		}

		@Watch('filter', {deep: true})
		private onFilterChanged(value: SkinListFilter): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: value});
			}
			if (value.text !== this.filterText) {
				this.filterText = value.text;
			}
		}

		@Watch('filterText')
		private onFilterTextChanged(value: string | null): void {
			clearTimeout(this.filterTextTimeout);
			this.filterTextTimeout = setTimeout(() => {
				this.filter.text = value;
				console.log('changing after debouncing');
			}, value !== null && value.length > 0 ? 500 : 25);
		}

		@Watch('preference', {deep: true})
		private onPreferenceChanged(value: SkinListPreference): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListPreference', {key: this.memoryKey, preference: value});
			}
		}

		@Watch('preference.cardSize')
		private onPreferenceCardSizeChanged(): void {
			(this.$refs.cards as SkinCard[] | undefined)?.forEach((card: SkinCard) => card.updateCardWidth());
		}

		public loadSkins(force: boolean = false): void {
			this.$store.dispatch('skin/loadSkins', force);
		}

		public sortEntries(entries: ISkin[], sortBy: string[], sortDesc: boolean[]): ISkin[] {
			return ArrayHelper.multiSort<ISkin>(entries, sortBy, sortDesc, new Map<string, MultiSortHandler<ISkin>>([
				['label', (a, b, column, desc) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase()) * (desc ? -1 : 1);
				}],
				['favourite', (a, b, column, desc) => {
					return (this.$store.getters['skin/isFavourite'](b.id) - this.$store.getters['skin/isFavourite'](a.id)) * (desc ? -1 : 1);
				}],
				['item', (a, b, column, desc) => {
					const itemA = a.item_id === null ? null : this.$store.getters['item/itemById'](a.item_id)?.label.toLowerCase() ?? null;
					const itemB = b.item_id === null ? null : this.$store.getters['item/itemById'](b.item_id)?.label.toLowerCase() ?? null;
					if (itemA === null && itemB === null) return 0;
					if (itemA !== null && itemB === null) return -1;
					if (itemA === null && itemB !== null) return 1;
					return itemA.localeCompare(itemB) * (desc ? -1 : 1);
				}],
				['kits', (a, b, column, desc) => {
					const kitsA = this.$store.state.kit.kits
						.filter((k: IKit) => k.skin_ids.includes(a.id))
						.map((k: IKit) => k.label.toLowerCase())
						.sort();
					const kitsB = this.$store.state.kit.kits
						.filter((k: IKit) => k.skin_ids.includes(b.id))
						.map((k: IKit) => k.label.toLowerCase())
						.sort();

					if (kitsA.length === 0 && kitsB.length === 0) return 0;
					if (kitsA.length === 0 && kitsB.length > 0) return desc ? -1 : 1;
					if (kitsA.length > 0 && kitsB.length === 0) return desc ? 1 : -1;
					return kitsA.join().localeCompare(kitsB.join()) * (desc ? -1 : 1);
				}],
			]));
		}

		public showSkin(id: number): void {
			const skin = this.$store.getters['skin/skinById'](id);
			if (skin === null) return;
			this.$router.push({name: Routes.skin, params: {id: skin.id.toString()}});
		}

		public workshopData(id: number): ISkinWorkshopData | null {
			return this.$store.getters['skin/workshopData'](id);
		}

		public performRemoveFromKit(skinId: number, kitId: number): void {
			const confirm = this.$refs.confirm as unknown as ConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				const kit: IKit | null = this.$store.getters['kit/kitById'](kitId);
				if (kit === null) return;

				this.loadingRemoveFromKit = true;
				this.$store.dispatch('kit/setSkins', {
					id: kit.id,
					skinIds: kit.skin_ids.filter(sid => skinId !== sid),
				})
					.finally(() => this.loadingRemoveFromKit = false);
			});
		}

	}
