
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import SkinHelper from "@/helpers/SkinHelper";

	@Component
	export default class SkinAvatar extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Number, default: 38})
		public size!: number;

		@Prop({type: Boolean, default: false})
		public noTooltip!: boolean;

		@Prop({type: Boolean, default: false})
		public useHd!: boolean;

		private get iconUrl(): string | null {
			return this.$store.state.ui.showAvatars
				? (this.useHd ? SkinHelper.iconUrlHd(this.skin) : SkinHelper.iconUrl(this.skin))
				: null;
		}

	}
