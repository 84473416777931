import IListFilter from "@/models/filter/IListFilter";
import {ISkin} from "@/models/ISkin";
import WordFilter from "@/helpers/WordFilter";
import store from "@/store";
import {IKit} from "@/models/IKit";

export default class SkinListFilter implements IListFilter<ISkin> {

	public text: string | null = null;
	public itemGroupId: number | null = null;
	public itemId: number | null = null;
	public kitId: number | null = null;
	public isTradeable: boolean | null = null;
	public isFavourite: boolean | null = null;
	public isPartOfKit: boolean | null = null;
	public minOwnedAmount: number | null = null;
	public author: string | null = null;
	public hasClassId: boolean | null = null;
	public hasSteamId: boolean | null = null;
	public hasWorkshopId: boolean | null = null;
	public hasIcon: boolean | null = null;
	public hasIconHd: boolean | null = null;
	public hasRustlabsUrl: boolean | null = null;
	public hasScmmUrl: boolean | null = null;

	public clear(): void {
		this.text = null;
		this.itemGroupId = null;
		this.itemId = null;
		this.kitId = null;
		this.isTradeable = null;
		this.isFavourite = null;
		this.isPartOfKit = null;
		this.minOwnedAmount = null;
		this.author = null;
		this.hasClassId = null;
		this.hasSteamId = null;
		this.hasWorkshopId = null;
		this.hasIcon = null;
		this.hasIconHd = null;
		this.hasRustlabsUrl = null;
		this.hasScmmUrl = null;
	}

	public hasFilter(): boolean {
		return this.text !== null
			|| this.itemGroupId !== null
			|| this.itemId !== null
			|| this.kitId !== null
			|| this.isTradeable !== null
			|| this.isFavourite !== null
			|| this.isPartOfKit !== null
			|| this.minOwnedAmount !== null
			|| this.author !== null
			|| this.hasClassId !== null
			|| this.hasSteamId !== null
			|| this.hasWorkshopId !== null
			|| this.hasIcon !== null
			|| this.hasIconHd !== null
			|| this.hasRustlabsUrl !== null
			|| this.hasScmmUrl !== null;
	}

	public applyFilter(models: ISkin[]): ISkin[] {
		const kit = this.kitId !== null ? store.getters['kit/kitById'](this.kitId) : null;
		const itemGroupMap = this.itemGroupId === null
			? new Map<number, number>()
			: models.reduce((acc: Map<number, number>, cur: ISkin) => {
				if (cur.item_id !== null && !acc.has(cur.item_id)) {
					const groupId = store.getters['item/itemById'](cur.item_id).group_id ?? null;
					if (groupId !== null) acc.set(cur.item_id, groupId);
				}
				return acc;
			}, new Map<number, number>());

		return models.filter(skin => {
			if (this.itemId !== null && skin.item_id !== this.itemId) return false;
			if (this.isTradeable !== null && skin.tradeable !== this.isTradeable) return false;
			if (this.minOwnedAmount !== null) {
				const owned = store.getters['inventory/amountBySkinId'](skin.id);
				if (owned < this.minOwnedAmount) return false;
			}
			if (this.text !== null && skin.id.toString() !== this.text && skin.class_id !== this.text && skin.steam_id?.toString() !== this.text && !WordFilter.matches(this.text, skin.label)) return false;
			if (kit !== null && !kit.skin_ids.includes(skin.id)) return false;
			if (this.isPartOfKit !== null) {
				const isPartOfKit = store.state.kit.kits.some((k: IKit) => k.skin_ids.includes(skin.id));
				if ((this.isPartOfKit && !isPartOfKit) || (!this.isPartOfKit && isPartOfKit)) return false;
			}
			if (this.isFavourite !== null && this.isFavourite !== store.getters['skin/isFavourite'](skin.id)) return false;
			if (this.itemGroupId !== null && (skin.item_id === null || itemGroupMap.get(skin.item_id) !== this.itemGroupId)) return false;
			if (this.author !== null && (skin.author === null || !WordFilter.matches(this.author, skin.author))) return false;
			if (this.hasClassId !== null && this.hasClassId === (skin.class_id === null)) return false;
			if (this.hasSteamId !== null && this.hasSteamId === (skin.steam_id === null)) return false;
			if (this.hasWorkshopId !== null && this.hasWorkshopId === (skin.workshop_id === null)) return false;
			if (this.hasIcon !== null && this.hasIcon !== skin.has_icon) return false;
			if (this.hasIconHd !== null && this.hasIconHd !== skin.has_icon_hd) return false;
			if (this.hasRustlabsUrl !== null && this.hasRustlabsUrl === (skin.rustlabs_url === null)) return false;
			if (this.hasScmmUrl !== null && this.hasScmmUrl === (skin.scmm_url === null)) return false;
			return true;
		});
	}

}
