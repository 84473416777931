
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import ItemChip from "@/components/item/ItemChip.vue";
	import SkinHelper from "@/helpers/SkinHelper";
	import {IKit} from "@/models/IKit";
	import KitCard from "@/components/kit/KitCard.vue";
	import SkinAmountChip from "@/components/skin/SkinAmountChip.vue";
	import SkinPriceChip from "@/components/skin/SkinPriceChip.vue";
	import Timestamp from "@/components/common/Timestamp.vue";
	import SkinFavouriteButton from "@/components/skin/SkinFavouriteButton.vue";
	import SkinChipLinkRustlabs from "@/components/skin/SkinChipLinkRustlabs.vue";
	import SkinChipLinkMarket from "@/components/skin/SkinChipLinkMarket.vue";
	import DetailHeader from "@/components/common/DetailHeader.vue";
	import {ISkinPricingEntry} from "@/models/ISkinPricingEntry";
	import DateHelper from "@/helpers/DateHelper";
	import SkinPricingEntriesCard from "@/components/skin-pricing-entry/SkinPricingEntriesCard.vue";
	import SkinPricingHistoryChart from "@/components/skin-pricing-entry/SkinPricingHistoryChart.vue";
	import SkinTradeableChip from "@/components/skin/SkinTradeableChip.vue";
	import SkinSetItemDialog from "@/components/skin/SkinSetItemDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import RouterHelper from "@/helpers/RouterHelper";
	import ProfitValues from "@/components/common/ProfitValues.vue";
	import SkinChipLinkWorkshop from "@/components/skin/SkinChipLinkWorkshop.vue";
	import SkinSetPriceItemStoreDialog from "@/components/skin/SkinSetPriceItemStoreDialog.vue";
	import SkinKitsDialog from "@/components/skin/SkinKitsDialog.vue";
	import ISkinWorkshopData from "@/models/ISkinWorkshopData";
	import SkinChipLinkAuthorWorkshop from "@/components/skin/SkinChipLinkAuthorWorkshop.vue";
	import SkinSetWorkshopIdDialog from "@/components/skin/SkinSetWorkshopIdDialog.vue";
	import SkinWorkshopGallery from "@/components/skin/SkinWorkshopGallery.vue";
	import CrawlJobLogsDialog from "@/components/crawl-job-log/CrawlJobLogsDialog.vue";
	import SkinSetIconUrlHdDialog from "@/components/skin/SkinSetIconUrlHdDialog.vue";
	import SkinSetIconUrlDialog from "@/components/skin/SkinSetIconUrlDialog.vue";
	import SkinChipLinkScmm from "@/components/skin/SkinChipLinkScmm.vue";
	import SkinSetClassIdDialog from "@/components/skin/SkinSetClassIdDialog.vue";

	@Component({
		components: {
			SkinSetClassIdDialog,
			SkinChipLinkScmm,
			SkinSetIconUrlDialog,
			SkinSetIconUrlHdDialog,
			CrawlJobLogsDialog,
			SkinWorkshopGallery,
			SkinSetWorkshopIdDialog,
			SkinChipLinkAuthorWorkshop,
			SkinKitsDialog,
			SkinSetPriceItemStoreDialog,
			SkinChipLinkWorkshop,
			ProfitValues,
			SkinSetItemDialog,
			SkinTradeableChip,
			SkinPricingHistoryChart,
			SkinPricingEntriesCard,
			DetailHeader,
			SkinChipLinkMarket,
			SkinChipLinkRustlabs,
			SkinFavouriteButton, Timestamp, SkinPriceChip, SkinAmountChip, KitCard, ItemChip
		}
	})
	export default class SkinView extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		public loadingPricingEntries: boolean = false;
		public pricingEntries: ISkinPricingEntry[] = [];
		public setItemDialog: DialogHandler = new DialogHandler();
		public setKitsDialog: DialogHandler = new DialogHandler();
		public setPriceItemStoreDialog: DialogHandler = new DialogHandler();
		public setWorkshopIdDialog: DialogHandler = new DialogHandler();
		public setClassIdDialog: DialogHandler = new DialogHandler();
		public setIconUrlDialog: DialogHandler = new DialogHandler();
		public setIconUrlHdDialog: DialogHandler = new DialogHandler();
		public crawlJobLogDialog: DialogHandler = new DialogHandler();
		public chartDataReady: boolean = false;
		public showChart: boolean = false;

		public created(): void {
			if (this.skin !== null && this.skin.tradeable !== false) {
				console.log('Automatically loading pricing entries of skin', this.skin.label);
				this.loadPricingEntries();
			}
		}

		public mounted(): void {
			this.showChart = true;
		}

		public get workshopData(): ISkinWorkshopData | null {
			return this.$store.getters['skin/workshopData'](this.idNumeric);
		}

		public get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

		public get idNumeric(): number | null {
			const id = Number.parseInt(this.id);
			return Number.isNaN(id) ? null : id;
		}

		public get skin(): ISkin | null {
			return this.idNumeric === null || !this.$store.state.ui.showAvatars
				? null
				: this.$store.getters['skin/skinById'](this.idNumeric);
		}

		public get iconUrl(): string | null {
			return this.skin === null ? null : SkinHelper.iconUrl(this.skin);
		}

		public get iconUrlHd(): string | null {
			return this.skin === null ? null : SkinHelper.iconUrlHd(this.skin);
		}

		public get kits(): IKit[] {
			return this.idNumeric === null
				? []
				: this.$store.getters['kit/kitsBySkinId'](this.idNumeric)
					.map((k: IKit) => k)
					.sort((a: IKit, b: IKit) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
		}

		public get marketUrl(): string | null {
			return this.skin === null ? null : SkinHelper.marketUrl(this.skin);
		}

		private get latestPricingEntry(): ISkinPricingEntry | null {
			return this.pricingEntries[this.pricingEntries.length - 1] ?? null;
		}

		private get latestPricingEntryDateTime(): string | null {
			return this.latestPricingEntry === null ? null : DateHelper.formatDateTime(this.latestPricingEntry.created_at);
		}

		private loadPricingEntries(): void {
			if (this.idNumeric === null || this.pricingEntries.length > 0) return;

			this.loadingPricingEntries = true;
			this.$skinService.pricingEntries(this.idNumeric, this.$store.state.skin.crawlingPricingEntriesEnabled)
				.then(data => {
					this.pricingEntries = data;
					const latestEntry = this.pricingEntries.length === 0 ? null : this.pricingEntries[this.pricingEntries.length - 1];
					if (latestEntry !== null && this.skin !== null && latestEntry.sell0 !== null && latestEntry.sell0 !== this.skin.price) {
						this.$store.dispatch('skin/loadSkin', this.skin.id)
							.finally(() => this.setChartDataReady());
					} else {
						this.setChartDataReady();
					}
				})
				.catch(err => console.error(err))
				.finally(() => this.loadingPricingEntries = false);
		}

		@Watch('skin.label', {immediate: true})
		private onSkinLabelChanged(value: string | null): void {
			RouterHelper.updateTitle(value);
		}

		@Watch('skin.workshop_id', {immediate: true})
		private onSkinWorkshopIdChanged(value: number | undefined | null): void {
			if (value !== null && value !== undefined && this.workshopData === null) {
				this.$store.dispatch('skin/loadWorkshopData', this.idNumeric)
					.then(() => this.$store.dispatch('skin/loadSkin', this.id));
			}
		}

		@Watch('skin.updated_at')
		private onSkinUpdatedAtChanged(): void {
			if (this.skin !== null && this.skin.tradeable !== false) {
				this.loadPricingEntries();
			}
		}

		private setChartDataReady(delayed: boolean = true) {
			setTimeout(() => this.chartDataReady = true, delayed ? 500 : 0);
		}

		private importScmmData(): void {
			if (this.skin === null || this.skin.class_id === null) return;
			this.$store.dispatch('skin/importScmmData', this.skin.id);
		}

	}
