
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import SkinAutocomplete from "@/components/skin/SkinAutocomplete.vue";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import {ISkin} from "@/models/ISkin";
	import ItemAutocomplete from "@/components/item/ItemAutocomplete.vue";

	@Component({
		components: {ItemAutocomplete, SkinAvatar, SkinAutocomplete}
	})
	export default class SkinSetClassIdDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		private loading: boolean = false;
		private classId: string | null = null;

		private reset(): void {
			this.classId = this.skin.class_id;
		}

		private trySave(): void {
			this.loading = true;
			this.$store.dispatch('skin/setClassId', {id: this.skin.id, classId: this.classId})
				.then(() => this.save(this.classId))
				.finally(() => this.loading = false);
		}

		@Watch('skin.class_id', {immediate: true})
		private onSkinClassIdChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(classId: string | null): string | null {
			return classId;
		}

	}
