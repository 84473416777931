import {State} from "@/store";
import {Module} from "vuex";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import {ISkin} from "@/models/ISkin";
import ISkinWorkshopData from "@/models/ISkinWorkshopData";

export interface Skin {
	loading: boolean,
	loaded: boolean,
	loadingFavourites: boolean,
	loadedFavourites: boolean,
	loadingSkinIds: number[],
	skins: ISkin[],
	workshopData: ISkinWorkshopData[],
	favourites: number[],
	latestChange: number | null,
	releaseDates: string[],
	crawlingPricingEntriesEnabled: boolean,
}

const skin: Module<Skin, State> = {
	namespaced: true,
	state: {
		loading: false,
		loaded: false,
		loadingFavourites: false,
		loadedFavourites: false,
		loadingSkinIds: [] as number[],
		skins: [] as ISkin[],
		workshopData: [] as ISkinWorkshopData[],
		favourites: [] as number[],
		latestChange: null,
		releaseDates: [] as string[],
		crawlingPricingEntriesEnabled: true,
	},
	getters: {
		skinById: (state: Skin) => (id: number): ISkin | null => {
			return state.skins.find(i => i.id === id) ?? null;
		},
		skinsByIds: (state: Skin) => (ids: number[]): ISkin[] => {
			return state.skins.filter(s => ids.includes(s.id));
		},
		skinsByItemId: (state: Skin) => (itemId: number): ISkin[] => {
			return state.skins.filter(s => s.item_id === itemId);
		},
		workshopData: (state: Skin) => (id: number): ISkinWorkshopData | null => {
			return state.workshopData.find(wsd => wsd.skin_id === id) ?? null;
		},
		isFavourite: (state: Skin) => (id: number): boolean => {
			return state.favourites.includes(id);
		},
		isSkinLoading: (state: Skin) => (id: number): boolean => {
			return state.loadingSkinIds.includes(id);
		},
		valueOfSkins: (state: Skin, getters) => (skinIds: number[]): number => {
			return getters['skinsByIds'](skinIds)
				.reduce((acc: number, cur: ISkin) => {
					return acc + (cur.price ?? 0);
				}, 0);
		},
		originalValueOfSkins: (state: Skin, getters) => (skinIds: number[]): number => {
			return getters['skinsByIds'](skinIds)
				.reduce((acc: number, cur: ISkin) => {
					return acc + (cur.price_item_store ?? 0);
				}, 0);
		},
		favourites: (state: Skin) => (): ISkin[] => {
			return state.skins.filter(s => state.favourites.includes(s.id));
		},
	},
	mutations: {
		setLoading(state: Skin, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setLoaded(state: Skin, isLoaded: boolean): void {
			state.loaded = isLoaded;
		},
		setLoadingFavourites(state: Skin, isLoading: boolean): void {
			state.loadingFavourites = isLoading;
		},
		setLoadedFavourites(state: Skin, isLoaded: boolean): void {
			state.loadedFavourites = isLoaded;
		},
		setSkinLoading(state: Skin, payload: { id: number, isLoading: boolean }): void {
			const isIncluded = state.loadingSkinIds.includes(payload.id);
			if (payload.isLoading && !isIncluded) {
				state.loadingSkinIds.push(payload.id);
			} else if (!payload.isLoading && isIncluded) {
				state.loadingSkinIds = state.loadingSkinIds.filter(id => id !== payload.id);
			}
		},
		setSkins(state: Skin, entries: ISkin[]): void {
			state.skins = entries;
		},
		setSkin(state: Skin, skin: ISkin): void {
			const index = state.skins.findIndex(s => s.id === skin.id);
			state.skins.splice(
				index < 0 ? state.skins.length + 1 : index,
				index < 0 ? 0 : 1,
				skin,
			);
		},
		setWorkshopData(state: Skin, data: ISkinWorkshopData): void {
			const index = state.workshopData.findIndex(s => s.skin_id === data.skin_id);
			state.workshopData.splice(
				index < 0 ? state.workshopData.length + 1 : index,
				index < 0 ? 0 : 1,
				data,
			);
		},
		clearLocalWorkshopData(state: Skin, skinId: number): void {
			if (state.workshopData.some(wd => wd.skin_id === skinId)) {
				state.workshopData = state.workshopData.filter(wd => wd.skin_id !== skinId);
			}
		},
		setFavourites(state: Skin, favouriteIds: number[]): void {
			state.favourites = favouriteIds;
		},
		clearUserData(state: Skin): void {
			state.favourites = [] as number[];
			state.loadedFavourites = false;
		},
		setLatestChange(state: Skin, latestChange: number | null): void {
			state.latestChange = latestChange;
		},
		setCrawlingPricingEntriesEnabled(state: Skin, value: boolean): void {
			state.crawlingPricingEntriesEnabled = value;
		},
		setReleaseDates(state: Skin, releaseDates: string[]): void {
			state.releaseDates = releaseDates;
		},
	},
	actions: {
		async loadLatestChange(context) {
			const latestChange = await Vue.$skinService.latestChange();
			context.commit('setLatestChange', latestChange);
			return latestChange;
		},
		async loadSkins(context, force: boolean = false) {
			if (context.state.loading) {
				console.log('loadSkins: already loading -> skip');
				return;
			}
			if (!force && context.state.loaded) {
				const latestChangeBefore = context.state.latestChange;
				const latestChangeCurrent = await context.dispatch('loadLatestChange');
				if (latestChangeBefore === latestChangeCurrent) {
					console.log('loadSkins: latest change checked and same as local -> skip');
					return;
				} else {
					console.log('loadSkins: latest change checked and NOT same as local!', latestChangeBefore, latestChangeCurrent);
				}
			}
			console.log(`loadSkins: loading (${force ? 'forced' : 'unforced'})`);

			const options = new AsiListTableOptions();
			options.page = 1;
			options.itemsPerPage = 0;

			context.commit('setLoading', true);
			try {
				const entries = (await Vue.$skinService.skins(options)).data;
				context.commit('setSkins', entries);
				context.dispatch('updateReleaseDates').then();
			} catch (e) {
				context.commit('setSkins', []);
				console.error('error while loading skins', e);
			} finally {
				context.commit('setLoading', false);
				context.commit('setLoaded', true);
			}
		},
		async loadFavourites(context, force: boolean = false) {
			if (context.state.loadingFavourites) {
				console.log('loadFavourites Skin: already loading -> skip');
				return;
			}
			if (context.state.loadedFavourites && !force) {
				console.log('loadFavourites Skin: already loaded -> skip');
				return;
			}

			context.commit('setLoadingFavourites', true);
			try {
				context.commit('setFavourites', await Vue.$skinService.favourites());
			} catch (e) {
				context.commit('setFavourites', []);
				console.error(`error while loading favourite skins:`, e);
			} finally {
				context.commit('setLoadingFavourites', false);
				context.commit('setLoadedFavourites', true);
			}
		},
		async setFavourite(context, payload: { id: number, isFavourite: boolean }) {
			const newFavourite = payload.isFavourite;
			const isFavourite = context.state.favourites.includes(payload.id);
			if (newFavourite === isFavourite) return;

			try {
				await Vue.$skinService.setFavourite(payload.id, newFavourite);
				if (newFavourite) {
					context.commit('setFavourites', context.state.favourites.concat([payload.id]));
				} else {
					context.commit('setFavourites', context.state.favourites.filter(id => id !== payload.id));
				}
			} catch (err) {
				console.error('error while setting skin favourite', payload);
			}
		},
		async loadSkin(context, id: number) {
			context.commit('setSkinLoading', {id: id, isLoading: true});
			try {
				const skin = await Vue.$skinService.skin(id);
				context.commit('setSkin', skin);
				context.dispatch('updateReleaseDates').then();
			} catch (e) {
				console.error(`error while loading skin ${id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: id, isLoading: false});
			}
		},
		async loadWorkshopData(context, id: number) {
			console.log('loading workshop data for skin', id);
			context.commit('setSkinLoading', {id: id, isLoading: true});
			try {
				const data = await Vue.$skinService.workshopData(id);
				context.commit('setWorkshopData', data);
			} catch (e) {
				console.error(`error while loading workshop data for skin ${id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: id, isLoading: false});
			}
		},
		async loadPriceOverview(context, id: number) {
			context.commit('setSkinLoading', {id: id, isLoading: true});
			try {
				//TODO: do something with price overview
				await Vue.$skinService.priceOverview(id);
				await context.dispatch('loadSkin', id);
			} catch (e) {
				console.error(`error while loading price overview for skin ${id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: id, isLoading: false});
			}
		},
		async loadMarketDetail(context, id: number) {
			context.commit('setSkinLoading', {id: id, isLoading: true});
			try {
				//TODO: do something with price overview
				await Vue.$skinService.marketDetail(id);
				await context.dispatch('loadSkin', id);
			} catch (e) {
				console.error(`error while loading price overview for skin ${id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: id, isLoading: false});
			}
		},
		async importScmmData(context, id: number) {
			context.commit('setSkinLoading', {id: id, isLoading: true});
			try {
				//TODO: do something with price overview
				await Vue.$skinService.importScmmData(id);
				await context.dispatch('loadSkin', id);
			} catch (e) {
				console.error(`error while loading scmm data for skin ${id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: id, isLoading: false});
			}
		},
		async setItem(context, payload: { id: number, itemId: number | null }) {
			context.commit('setSkinLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$skinService.setItem(payload.id, payload.itemId);
				await context.dispatch('loadSkin', payload.id);
			} catch (e) {
				console.error(`error while setting item of skin ${payload.id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: payload.id, isLoading: false});
			}
		},
		async setPriceItemStore(context, payload: { id: number, priceItemStore: number | null }) {
			context.commit('setSkinLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$skinService.setSetPriceItemStore(payload.id, payload.priceItemStore);
				await context.dispatch('loadSkin', payload.id);
			} catch (e) {
				console.error(`error while setting item store price of skin ${payload.id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: payload.id, isLoading: false});
			}
		},
		async setWorkshopId(context, payload: { id: number, workshopId: number | null }) {
			context.commit('setSkinLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$skinService.setWorkshopId(payload.id, payload.workshopId);
				await context.dispatch('loadSkin', payload.id);
				context.commit('clearLocalWorkshopData', payload.id);
			} catch (e) {
				console.error(`error while setting workshop id of skin ${payload.id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: payload.id, isLoading: false});
			}
		},
		async setClassId(context, payload: { id: number, classId: string | null }) {
			context.commit('setSkinLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$skinService.setClassId(payload.id, payload.classId);
				await context.dispatch('loadSkin', payload.id);
				context.commit('clearLocalWorkshopData', payload.id);
			} catch (e) {
				console.error(`error while setting class id of skin ${payload.id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: payload.id, isLoading: false});
			}
		},
		async setIconUrl(context, payload: { id: number, url: string | null }) {
			context.commit('setSkinLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$skinService.setIconUrl(payload.id, payload.url);
				await context.dispatch('loadSkin', payload.id);
			} catch (e) {
				console.error(`error while setting icon url of skin ${payload.id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: payload.id, isLoading: false});
			}
		},
		async setIconUrlHd(context, payload: { id: number, url: string | null }) {
			context.commit('setSkinLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$skinService.setIconUrlHd(payload.id, payload.url);
				await context.dispatch('loadSkin', payload.id);
			} catch (e) {
				console.error(`error while setting icon url hd of skin ${payload.id}`, e);
			} finally {
				context.commit('setSkinLoading', {id: payload.id, isLoading: false});
			}
		},
		async updateReleaseDates(context) {
			context.commit('setReleaseDates', context.state.skins
				.filter(s => s.release_date !== null)
				.reduce((acc: string[], cur: ISkin) => {
					if (!acc.includes(cur.release_date!)) {
						acc.push(cur.release_date!);
					}
					return acc;
				}, [] as string[])
				.sort((a, b) => a.localeCompare(b) * -1));

			const curSelectedReleaseDate = context.rootState.ui.home.selectedReleaseDate;
			const defaultReleaseDate = context.state.releaseDates[0] ?? null;
			if (curSelectedReleaseDate === null || !context.state.releaseDates.includes(curSelectedReleaseDate)) {
				context.commit('ui/setHomeSelectedReleaseDate', defaultReleaseDate, {root: true});
			}
		},
	},

};

export default skin;
